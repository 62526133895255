/* global BigInt */
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import styles from './App.module.css';
import logo from './logo.png';
import clickSound from './onclick.mp3';
import mouseSound from './onmouse.mp3';
import successSound from './bdot.mp3';
import numeral from 'numeral';


function App() {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState('');
  const [tokenBalance, setTokenBalance] = useState('');
  const [contract, setContract] = useState(null);
  const [mintedTokens, setMintedTokens] = useState(0); 
  const [networkError, setNetworkError] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPhase, setCurrentPhase] = useState('');
  const [mintCost, setMintCost] = useState('');
  const [remainingTime, setRemainingTime] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false); 
  const [formattedTokens, setFormattedTokens] = useState('');
  const [lastTransactionHash, setLastTransactionHash] = useState('');
  const [initialBalance, setInitialBalance] = useState('');
  const [isBlastNetwork, setIsBlastNetwork] = useState(false);
  const [ethToUsdRate, setEthToUsdRate] = useState(0); // Stan na kurs ETH do USD
  const [totalSupply, setTotalSupply] = useState(0);

  
  const BLAST_CHAIN_ID = '0x13e31';
  const BLAST_RPC_URL = 'https://rpc.blast.io';


  useEffect(() => {
	const checkNetwork = async () => {
	  if (window.ethereum) {
		try {
		  // Użycie getChainId() i porównanie wartości jako ciągów szesnastkowych
		  const chainId = await window.ethereum.request({ method: 'eth_chainId' });
		  setIsBlastNetwork(chainId === BLAST_CHAIN_ID);
		} catch (error) {
		  console.error('Error checking network:', error);
		}
	  }
	};
  
	checkNetwork();
  
	// Nasłuchiwanie na zmianę sieci
	window.ethereum && window.ethereum.on('chainChanged', checkNetwork);
  
	// Nasłuchiwanie na zmianę kont
	window.ethereum && window.ethereum.on('accountsChanged', () => {
	  // Może być konieczne ponowne połączenie z portfelem po zmianie konta
	  checkNetwork();
	});
  
	return () => {
	  // Oczyszczenie nasłuchiwaczy
	  window.ethereum && window.ethereum.removeListener('chainChanged', checkNetwork);
	  window.ethereum && window.ethereum.removeListener('accountsChanged', checkNetwork);
	};
  }, []);
  


  useEffect(() => {
    if (web3 && account && contract) {
      fetchData();
    }
  }, [web3, account, contract]);


  useEffect(() => {
	const handleAccountsChanged = (accounts) => {
	  if (accounts.length === 0) {
		// MetaMask jest zablokowany lub użytkownik nie jest połączony z kontem.
		console.log('Please connect to MetaMask.');
	  } else if (accounts[0] !== account) {
		setAccount(accounts[0]);
		// Możesz również odświeżyć inne dane związane z kontem tutaj.
	  }
	};
  
	if (window.ethereum) {
	  window.ethereum.on('accountsChanged', handleAccountsChanged);
	}
  
	// Oczyszczanie efektu ubocznego
	return () => {
	  if (window.ethereum) {
		window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
	  }
	};
  }, [account]); // Zależność od `account`, aby zaktualizować go w stanie, gdy się zmieni.
  


  useEffect(() => {
    const fetchTransactionConfirmation = async () => {
      if (!lastTransactionHash) return;
      
      try {
        const receipt = await web3.eth.getTransactionReceipt(lastTransactionHash);
        if (receipt && receipt.status) {
          await fetchData();
          setShowSuccessModal(true);
        }
      } catch (error) {
        console.error('Error fetching transaction confirmation:', error);
      }
    };

    fetchTransactionConfirmation();
  }, [lastTransactionHash, web3]);


  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const web3Instance = new Web3(window.ethereum);
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3Instance.eth.getAccounts();
        setWeb3(web3Instance);
        setAccount(accounts[0]);

        const isBlastNetwork = await switchToBlastNetwork();
        if (!isBlastNetwork) {
          setNetworkError('This network is not supported. Please switch to the BLAST network.');
          return;
        }
        setNetworkError('');

        const contractAddress = '0x2d6ee21515Adbd84D3aA7FCfa4fA3df99F143Ef2'; // Your contract address
        const contractABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "allowance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "needed",
				"type": "uint256"
			}
		],
		"name": "ERC20InsufficientAllowance",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "balance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "needed",
				"type": "uint256"
			}
		],
		"name": "ERC20InsufficientBalance",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "approver",
				"type": "address"
			}
		],
		"name": "ERC20InvalidApprover",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "receiver",
				"type": "address"
			}
		],
		"name": "ERC20InvalidReceiver",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "ERC20InvalidSender",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "ERC20InvalidSpender",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "OwnableInvalidOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "OwnableUnauthorizedAccount",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getCurrentPhase",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getMintCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "lastMintBlock",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "phase10EndBlock",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "phase1EndBlock",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "phase2EndBlock",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "phase3EndBlock",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "phase4EndBlock",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "phase5EndBlock",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "phase6EndBlock",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "phase7EndBlock",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "phase8EndBlock",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "phase9EndBlock",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

        const contractInstance = new web3Instance.eth.Contract(contractABI, contractAddress);
        setContract(contractInstance);
      } catch (error) {
        console.error("Could not connect to wallet:", error);
      }
    } else {
      alert('Please install MetaMask!');
    }
  };

  const switchToBlastNetwork = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: BLAST_CHAIN_ID }],
      });
      return true;
    } catch (switchError) {
      console.error('Error switching to BLAST network:', switchError);
      return false;
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const balance = await contract.methods.balanceOf(account).call();
      setTokenBalance(web3.utils.fromWei(balance.toString(), 'ether')); 
  
      const phase = await contract.methods.getCurrentPhase().call();
      setCurrentPhase(phase);
      const cost = await contract.methods.getMintCost().call();
      setMintCost(web3.utils.fromWei(cost.toString(), 'ether'));

      const supply = await contract.methods.totalSupply().call();
      setTotalSupply(web3.utils.fromWei(supply, 'ether'));
  
      if (parseInt(phase) < 10) { 
        const nextPhaseEndBlock = await contract.methods[`phase${parseInt(phase) + 1}EndBlock`]().call();
        const currentBlock = await web3.eth.getBlockNumber();
        const blocksRemaining = BigInt(nextPhaseEndBlock) - BigInt(currentBlock);
        const secondsRemaining = Number(blocksRemaining) * 2; 
        const minutesRemaining = Math.floor(secondsRemaining / 60);
        const hoursRemaining = Math.floor(minutesRemaining / 60);
        const daysRemaining = Math.floor(hoursRemaining / 24);
        const remainingTime = `${daysRemaining} days, ${hoursRemaining % 24} hours, ${minutesRemaining % 60} minutes`;
        setRemainingTime(remainingTime);
      } else {
        const phase10EndBlock = await contract.methods.phase10EndBlock().call(); 
        const currentBlock = await web3.eth.getBlockNumber();
        const blocksRemaining = BigInt(phase10EndBlock) - BigInt(currentBlock);
        const secondsRemaining = Number(blocksRemaining) * 2; 
        const minutesRemaining = Math.floor(secondsRemaining / 60);
        const hoursRemaining = Math.floor(minutesRemaining / 60);
        const daysRemaining = Math.floor(hoursRemaining / 24);
        const remainingTime = `${daysRemaining} days, ${hoursRemaining % 24} hours, ${minutesRemaining % 60} minutes`;
        setRemainingTime(remainingTime);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    const fetchEthToUsdRate = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
        if (!response.ok) {
          throw new Error('Error ETH price API');
        }
        const data = await response.json();
        setEthToUsdRate(data.ethereum.usd); // Aktualizacja stanu kursu
      } catch (error) {
        console.error('Error ETH price API:', error);
      }
    };

    fetchEthToUsdRate(); 
  }, []);



  const mintToken = async () => {
    if (!web3 || !contract) {
      alert('Please connect your wallet first.');
      return;
    }
  
    try {
      const mintCost = await contract.methods.getMintCost().call();
      const initialBalance = await contract.methods.balanceOf(account).call();
      console.log('Initial Balance:', initialBalance);
      setInitialBalance(initialBalance);
  
      const transaction = await contract.methods.mint().send({ from: account, value: mintCost });
      setLastTransactionHash(transaction.transactionHash);
    } catch (error) {
      console.error('Minting failed:', error);
      alert('Minting failed. Try again!');
    }
  };


  useEffect(() => {
    const fetchTransactionConfirmation = async () => {
      if (!lastTransactionHash) return;
      
      try {
        const receipt = await web3.eth.getTransactionReceipt(lastTransactionHash);
        if (receipt && receipt.status) {
          await fetchData();
          const finalBalance = await contract.methods.balanceOf(account).call();
          console.log('Final Balance:', finalBalance);
          const tokensMinted = BigInt(finalBalance) - BigInt(initialBalance);
          const formattedTokensValue = web3.utils.fromWei(tokensMinted.toString(), 'ether');
          console.log('Formatted Tokens Minted:', formattedTokensValue);
          setTokenBalance(web3.utils.fromWei(finalBalance.toString(), 'ether'));
          setMintedTokens(formattedTokensValue);
          setFormattedTokens(formattedTokensValue);
          setShowSuccessModal(true);
          playSuccessSound();
        }
      } catch (error) {
        console.error('Error fetching transaction confirmation:', error);
      }
    };

    fetchTransactionConfirmation();
  }, [lastTransactionHash, web3]);

  const closeModal = () => {
    setShowSuccessModal(false);
  };

  const playClickSound = () => {
    const audio = new Audio(clickSound);
    audio.play();
  };

  const playMouseSound = () => {
    const audio = new Audio(mouseSound);
    audio.play();
  };

  const playSuccessSound = () => {
    const audio = new Audio(successSound);
    audio.play();
  };

  const mintCostUsd = Number(mintCost) * ethToUsdRate;

  return (
    <div className={styles.App}>
      <img src={logo} alt="Logo" className={styles.logo} />
      <h1>MINT your LAST</h1>
      {networkError && <p className={styles.error}>{networkError}</p>}
      {!account ? (
        <button
          onMouseEnter={playMouseSound}
          onClick={() => {
            connectWallet();
            playClickSound();
          }}
          className={styles.button}
        >
          Connect Wallet
        </button>
      ) : (
        <>
          {loading ? (
            <div><p>Loading...</p></div>
          ) : isBlastNetwork ? (
            <>
              <button
                onMouseEnter={playMouseSound}
                onClick={() => {
                  setAccount('');
                  playClickSound();
                }}
                className={styles.button}
              >
                Disconnect Wallet
              </button>
              <div>
                <p>
                  Token Balance: <span className={styles.styledVariable}>{Number(tokenBalance).toLocaleString()}</span>{' '}
                  <span className={styles.styledVariable}>LAST</span>
                </p>
                <p>
                  Current Phase: <span className={styles.styledVariable}>{currentPhase}</span>
                </p>
                <p>
                  Mint Cost: <span className={styles.styledVariable}>{mintCost}</span>{' '}
                  <span className={styles.styledVariable}> ETH / ${(ethToUsdRate * mintCost).toFixed(2)}</span>
                </p>
                <p>
                  Total Supply: <span className={styles.styledVariable}>{Number(totalSupply).toLocaleString()} LAST</span>
                </p>
                <p>
                  Remaining Time: <span className={styles.styledVariable}>{remainingTime}</span>
                </p>
                <p className={styles.randomnessInfo}>
                  *The minting result may vary randomly by -50% to +100% from the base 100 000 tokens.<br />Each phase lasts about 7 days, each phase the mint cost will increase by 20%<br />LP will be created after Phase 1.
                </p>
                <button
                  onMouseEnter={playMouseSound}
                  onClick={() => {
                    mintToken();
                    playClickSound();
                  }}
                  className={styles.button}
                >
                  Mint Token
                </button>
              </div>
            </>
          ) : (
            <p>Please switch to the BLAST network in MetaMask.</p>
          )}
        </>
      )}
      {showSuccessModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <span className={styles.close} onClick={closeModal}>&times;</span>
            <h2 className={styles.styledVariable}>Success!</h2>
            <p>You have successfully minted</p>
            <p className={styles.styledVariable}>
              {formattedTokens ? `${numeral(formattedTokens).format('0,0')} LAST` : 'LAST'}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}  

export default App;